import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Headerlogosvg, LoginIcon } from "../../assets/icons/Icon";
import { LOCATION_API } from "../../config";
import useAuth from "../../hooks/useAuth";
import axiosInstance from "../../utils/axios";
import CommonToast from "../../components/common/Toast/CommonToast";
import { useNavigate } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
const Login = () => {
  const { login, user } = useAuth();
  const { printToast } = CommonToast();
  const [locationData, setLocationData] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [errormsg, setErrormsg] = useState();
  const navigate = useNavigate();
  const { onToggle, isOpen } = useDisclosure();
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setLoading(true);
    setIsSubmitting(true);
    if (username && password) {
      try {
        await login(username, password, locationData);
        // toast({
        //     description: 'login successfully',
        //     position: 'top-right',
        //     status: 'success',
        //     duration: 4000,
        //     isClosable: true,
        //   });
        const data = {
          description: "Login Successfully",
          status: "success",
        };
        printToast(data);
        console.log("change_password", user.change_password);
        // toast({
        //   title: "Login Successfully",
        //   position: "top-right",
        //   isClosable: true,
        //   status: "success",
        //   duration: 5000,
        // });
        if (user.change_password === 0) {
          console.log("change_password", user.change_password);
          navigate("/change-password");
        }
      } catch (error) {
        setError(true);
        setErrormsg(error.response.data);
      }
    }
    setLoading(false);
    setIsSubmitting(false);
  };
  useEffect(() => {
    if (!username && !password) {
      setError();
    }
  }, [username, password]);

  //creating function to load ip address from the API
  const getLocationData = async () => {
    const res = await axiosInstance.get(`${LOCATION_API}?fields=61439`);
    setLocationData(res.data);
  };

  useEffect(() => {
    //passing getLocationData method to the lifecycle method
    getLocationData();
  }, []);
  return (
    // <Box className='app-body login-agbody login-body'>
    //     <Box className='login-wrap'></Box>
    // </Box>
    <Flex
      align={"center"}
      justify={"center"}
      height={{ base: "100vh", sm: "100vh", md: "100vh" }}
      style={{ background: "url(../bg-login.jpg)" }}
    >
      <Box
        className="login-wrap"
        style={{
          backdropFilter: "blur(2px)",
          boxShadow: "0px 10px 15px 10px rgb(0 0 0 / 15%)",
          WebkitBackdropFilter: "blur(2px)",
          backgroundColor: "rgb(228 228 228 / 15%)",
          zIndex: 1,
        }}
        // bg="linear-gradient(180deg, #580575 8%, #4a0562 100%)"
        // bg="linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)"
        p={6}
        rounded="md"
        w={{ base: "100%", sm: "100%", md: "540px" }}
        py={{ base: "20px", sm: "20px", md: "100px" }}
        px={{ base: "50px", sm: "50px", md: "150px" }}
        // height={{base:'100%',sm:'100%',md:'auto'}}
        mx={{ base: "2", sm: "2", md: "0" }}
      >
        <Image
          className="logo_wrap"
          w={"250px"}
          margin={"auto"}
          pb={"10px"}
          // src="/jdlogopng.png"
          src="/jdexlogo.svg"
          alt=""
        />
        <Text
          fontSize="25px"
          lineHeight="24px"
          mb="15px"
          fontWeight="500"
          color="#fff"
          className="log"
          display={'flex'}
          justifyContent={'center'}

        >
          Login
        </Text>
        <form onSubmit={submitHandler}>
          <VStack spacing={4} align="flex-start">
            {isSubmit && error ? (
              <Text fontWeight={400} mt={0} color={"red"}>
                {error}
              </Text>
            ) : null}
            <FormControl>
              <Input
                className="Form_Control"
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
              />
              {isSubmit && !username ? (
                <FormHelperText fontWeight={600} mt={0} color={"red"}>
                  Please enter username
                </FormHelperText>
              ) : null}
            </FormControl>
            <FormControl>
              <InputGroup>
                <Input
                  className="Form_Control"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  type={isOpen ? "text" : "password"}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();  // Prevent space from being entered
                    }
                  }}
                />
                <InputRightElement width="2.5rem" height={"2rem"}>
                  <Box
                    cursor={"pointer"}
                    bg={"transparent !important"}
                    onClick={onToggle}
                  >
                    {isOpen ? (
                      <ViewIcon color={"black"} />
                    ) : (
                      <ViewOffIcon color={"black"} />
                    )}
                  </Box>
                </InputRightElement>
              </InputGroup>
              {isSubmit && !password ? (
                <FormHelperText fontWeight={600} mt={0} color={"red"}>
                  Please enter password
                </FormHelperText>
              ) : null}
            </FormControl>
            {error && (
              <Text color={"red"} mb={1}>
                {error
                  ? errormsg
                  : "Login name or password is invalid! Please try again."}
              </Text>
            )}
            <Box w="100%" className="LogoutIcon">
              <Button
                background="#000"
                color="#fff"
                fontSize={15}
                type="submit"
                isLoading={loading}
                loadingText="Loading..."
                disabled={isSubmitting}
                _hover={"#000"}
                w="100%"
                position="relative"
              >
                <Box
                  margin="auto"
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform="translate(-50%, -50%)"
                >
                  Login <LoginIcon />
                </Box>
              </Button>
            </Box>
          </VStack>
        </form>
      </Box>
    </Flex>
  );
};

export default Login;
