import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import AuthGuard from '../guards/AuthGuard';
import HeaderLayout from '../layouts/HeaderLayout';
// components
import LoadingScreen from '../components/LoadingScreen.js';
import GuestGuard from '../guards/GuestGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import { Box } from '@chakra-ui/react';
import ChangePasswordFirstLogin from '../pages/auth/ChangePassword.js';



// import EventProfitLoss from '../pages/MyReports/EventProfitLoss';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'change-password',
      children: [
        { path: '', element: <AuthGuard><ChangePasswordFirstLogin /></AuthGuard> },
      ],
    },
    {
      path: '',
      children: [
        { path: '', element: <AuthGuard><HeaderLayout /><Dashboard /> </AuthGuard> },
        { path: 'dashboard', element: <AuthGuard><HeaderLayout /><Dashboard /> </AuthGuard> },
        // { path: '', element: <Navigate to="/dashboard" replace /> },
      ],
      element:
        <AuthGuard>
          <HeaderLayout />
          <Dashboard />
        </AuthGuard>
    },
    {
      path: '',
      children: [
        { path: 'user/list', element: <AuthGuard><RoleBasedGuard roles={[2, 3, 4]}><HeaderLayout /><UserDownList /></RoleBasedGuard> </AuthGuard> },
        { path: 'master/list', element: <AuthGuard><RoleBasedGuard roles={[2, 3]}><HeaderLayout /><MasterDownList /></RoleBasedGuard> </AuthGuard> },
        { path: 'super_master/list', element: <AuthGuard><RoleBasedGuard roles={[2]}><HeaderLayout /><SuperMasterDownList /> </RoleBasedGuard></AuthGuard> },
      ],
    },
    {
      path: 'my-account',
      children: [
        { path: 'profile', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><Profile /></RoleBasedGuard> </AuthGuard> },
        { path: 'user_profile', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><UserProfile /></RoleBasedGuard> </AuthGuard> },
        { path: 'master_profile', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><MasterProfile /></RoleBasedGuard> </AuthGuard> },
        { path: 'super_master_profile', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><MasterProfile /></RoleBasedGuard> </AuthGuard> },
        { path: 'user_event_profitloss/sprotID=:sportid/StartDate=:startdate/EndDate=:enddate', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><Usereventprofitloss /></RoleBasedGuard></AuthGuard> },
      ],
    },
    {
      path: 'market_event_profitloss/eventid=:eventid/StartDate=:startdate/EndDate=:enddate',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><UserMarketprofitloss /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'bethistory/sportId=:sportid/marketId=:marketid/StartDate=:startdate/EndDate=:enddate',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><Userbetlist /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'setting',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]} ><HeaderLayout /><SportsList /></RoleBasedGuard> </AuthGuard> },
        { path: 'tournaments/:id', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]} ><HeaderLayout /><Tournaments /></RoleBasedGuard> </AuthGuard> },
        { path: 'matches/:id', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]} ><HeaderLayout /><MatchList /></RoleBasedGuard> </AuthGuard> },
        { path: 'market_events/:id', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]} ><HeaderLayout /><MarketEvents /></RoleBasedGuard> </AuthGuard> },
        { path: 'manage-fancy', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]} ><HeaderLayout /><ManageFancy /></RoleBasedGuard> </AuthGuard> },
        { path: 'liveline-mapping', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]} ><HeaderLayout /><LiveMappingTable /></RoleBasedGuard> </AuthGuard> },
        { path: 'match-result', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]} ><HeaderLayout /><MatchResult /></RoleBasedGuard> </AuthGuard> },
        { path: 'match-min-max', element: <AuthGuard><RoleBasedGuard roles={[2]} ><HeaderLayout /><MatchMinMax /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'reports',
      children: [
        { path: 'profit-loss', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><EventProfitLoss /></RoleBasedGuard> </AuthGuard> },
        { path: 'downline-pl', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><DownlineProfitLoss /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'ProfitLossEvents/:sportId/:startDate/:endDate',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><ProfitLossEvents /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'ProfitLossMarket/:exeventid/:startDate/:endDate',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><ProfitLossMarket /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'ProfitLossuser/:sportId/:marketid/:startDate/:endDate',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><ProfitLossUser /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'betuserhistory/:userId/:sportId/:marketid/:startDate/:endDate',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><BetResultHistory /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'password-history',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><PasswordHistory /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'bet-list',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><BetList /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'market-analysis',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><MarketAnalysis /></RoleBasedGuard> </AuthGuard> },
        // { path: 'market-details', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><MarketDetails /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'market-details/:eventId/:sportId/:eventName',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><MarketDetails /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'banking',
      children: [
        { path: 'user-banking', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><UserBanking /></RoleBasedGuard> </AuthGuard> },
        { path: 'master-banking', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><DownlineProfitLoss /></RoleBasedGuard> </AuthGuard> },
        { path: 'masterbanking', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><MasterBanking /></RoleBasedGuard> </AuthGuard> },
        { path: 'SupermasterBanking', element: <AuthGuard><RoleBasedGuard roles={[1, 2]}><HeaderLayout /><SupermasterBanking /></RoleBasedGuard></AuthGuard> },
        { path: 'BankingRequest', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><BankingRequest /></RoleBasedGuard></AuthGuard> }

      ],
    },
    {
      path: 'commission',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><Commission /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'restore-user',
      children: [
        { path: '', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><RestoreUser /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: 'extra',
      children: [
        { path: 'banner-update', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><BannerUpdate /></RoleBasedGuard> </AuthGuard> },
        { path: 'news-update', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><NewsUpdate /></RoleBasedGuard> </AuthGuard> },
        { path: 'client-notification', element: <AuthGuard><RoleBasedGuard roles={[1, 2, 3, 4]}><HeaderLayout /><CilentNotification /></RoleBasedGuard> </AuthGuard> },
      ],
    },
    {
      path: '*',
      children: [
        { path: '', element: <NotFound /> },
        { path: '404', element: <NotFound /> },
        // { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard/Dashboard')));
const UserDownList = Loadable(lazy(() => import('../pages/List/UserList')));
const MasterDownList = Loadable(lazy(() => import('../pages/List/MasterList')));
const SuperMasterDownList = Loadable(lazy(() => import('../pages/List/SuperMasterList')));

//Profile
const Profile = Loadable(lazy(() => import('../pages/MyAccountTableLink/Profile')));
const UserProfile = Loadable(lazy(() => import('../pages/MyAccountTableLink/Profile/UserProfile')));
const MasterProfile = Loadable(lazy(() => import('../pages/MyAccountTableLink/Profile/MasterProfile')));
// const SuperMasterProfile = Loadable(lazy(() => import('../pages/MyAccountTableLink/Profile/SuperMasterProfile')));

//PasswordHistory
const PasswordHistory = Loadable(lazy(() => import('../pages/PasswordHistory/PasswordHistory')));

//Commission
const Commission = Loadable(lazy(() => import('../pages/Commission/Commission')));

//RestoreUser
const RestoreUser = Loadable(lazy(() => import('../pages/RestoreUser/RestoreUser')));

//Setting
const SportsList = Loadable(lazy(() => import('../pages/AdminPages/SportsList')));
const Tournaments = Loadable(lazy(() => import('../pages/AdminPages/Tournaments')));
const MatchList = Loadable(lazy(() => import('../pages/AdminPages/MatchList')));
const MarketEvents = Loadable(lazy(() => import('../pages/AdminPages/MarketEvents')));
const ManageFancy = Loadable(lazy(() => import('../pages/AdminPages/ManageFancy')));
const LiveMappingTable = Loadable(lazy(() => import('../pages/AdminPages/LiveMappingTable')));
const MatchResult = Loadable(lazy(() => import('../pages/AdminPages/MatchResult')));
const MatchMinMax = Loadable(lazy(() => import('../pages/AdminPages/MatchMinMax.js')));

//BannerUpdate
const BannerUpdate = Loadable(lazy(() => import('../pages/Extra/BannerUpdate')))
const CilentNotification = Loadable(lazy(() => import('../pages/Extra/CilentNotification')))
const NewsUpdate = Loadable(lazy(() => import('../pages/Extra/NewsUpdate')))


const NotFound = Loadable(lazy(() => import('../pages/Page404.js')));

//my report
const EventProfitLoss = Loadable(lazy(() => import('../pages/MyReports/EventProfitLoss')))
const DownlineProfitLoss = Loadable(lazy(() => import('../pages/MyReports/DownlineProfitLoss')))
const ProfitLossEvents = Loadable(lazy(() => import('../pages/MyReports/ProfitLossEvents')))
const ProfitLossMarket = Loadable(lazy(() => import('../pages/MyReports/ProfitLossMarket')))
const ProfitLossUser = Loadable(lazy(() => import('../pages/MyReports/ProfitLossUser')))
const BetResultHistory = Loadable(lazy(() => import('../pages/MyReports/BetResultHistory')))

//BetList
const BetList = Loadable(lazy(() => import('../pages/BetList/BetList')))

//MarketAnalysis
const MarketAnalysis = Loadable(lazy(() => import('../pages/MarketAnalysis/MarketAnalysis')))
const MarketDetails = Loadable(lazy(() => import('../pages/MarketAnalysis/MarketDetails/MarketDetails')))

const UserBanking = Loadable(lazy(() => import('../pages/Banking/UserBanking')))
const MasterBanking = Loadable(lazy(() => import('../pages/Banking/MasterBanking')))
const SupermasterBanking = Loadable(lazy(() => import('../pages/Banking/SupermasterBanking')))
const BankingRequest = Loadable(lazy(() => import('../pages/Banking/BankingRequest')))

//event profile
const Usereventprofitloss = Loadable(lazy(() => import('../pages/MyAccountTableLink/Profile/Usereventprofitloss')))
const UserMarketprofitloss = Loadable(lazy(() => import('../pages/MyAccountTableLink/Profile/UserMarketprofitloss')))
const Userbetlist = Loadable(lazy(() => import('../pages/MyAccountTableLink/Profile/Userbetlist')))
