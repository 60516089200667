import { useToast } from '@chakra-ui/react';
const CommonToast = () => {
    const toast = useToast();
    const id = 'test-toast';
    // types are: "success", "info", "warning", "error"
    const printToast = (d, extraArgs) => {
        if (!d) {
            throw new Error("The 'd' object is not defined.");
        }
        if (!toast.isActive(id)) {
            const validTypes = ['success', 'info', 'warning', 'error'];
            const allowedPositions = ["top-right", "top", "top-left", "bottom-right", "bottom", "bottom-left"];

            const status = validTypes.includes(d.status) ? d.status : 'info';
            const position = allowedPositions.includes(d.position) ? d.position : "top-right";
            const duration = typeof d.duration === 'number' ? d.duration : 5000;
            const description = d.description || '';
            toast({
                id,
                title: d.title,
                description: description,
                status: status,
                position,
                isClosable: true,
                duration,
                variant: 'solid',
                ...extraArgs,
            })
        }
    }
    return { printToast };
}
export default CommonToast