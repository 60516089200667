import {
  Box,
  Container,
  Flex,
  Image,
  Link,
  List,
  ListItem,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Headerlogosvg, RefresIcon } from "../../assets/icons/Icon";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Button } from "antd";
const Header = () => {
  const navigate = useNavigate();
  const { user, updateAuth } = useAuth();
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    await updateAuth();
    setLoading(false);
  }

  return (
    <Box
      bg={
        "linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)"
      } //{"linear-gradient(180deg,#580575 8%,#4a0562 100%)"}
    >
      <Container maxW="sm,md,lg,xl">
        <Flex align={"center"}>
          <Box height={"75px"} paddingTop={"15px"} position={"relative"}>
            <Link onClick={() => navigate(`/`)}>
              {/* <Headerlogosvg width={"160px"} height={"68px"} mt={"-9px"} /> */}
              <Image
                mt={"-9px"}
                w={"160px"}
                h={"68px"}
                src="/jdexlogo.svg"
                alt=""
              />
            </Link>
          </Box>
          <Spacer />
          <Box display='flex'>
            {
              !loading ?
                (
                  <>
                    <List
                      justifyContent={{ base: 'end', sm: 'end', md: 'inherit' }}
                      marginLeft={{ base: '2%!important', sm: '2%!important', md: 'auto!important' }}
                      marginBottom={{ base: '0!important', sm: '0!important', md: '1rem!important' }}
                      color={"#fff"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      className="Header_Right"
                      spacing={{ base: '1', sm: '1', md: '0' }}
                    >
                      <ListItem>
                        <Text as="span" className="Label">
                          WHITE_LABEL
                        </Text>
                        <strong> {user?.username} </strong>
                      </ListItem>
                      <ListItem>
                        <strong>IRP {user?.opening_balance}</strong>
                      </ListItem>
                      {/* <ListItem
                            isLoading={loading}
                            className="Refres_button"
                            // onClick={updateAuth}
                            onClick={getData}
                          >
                            <RefresIcon />
                        </ListItem> */}
                    </List>
                  </>
                ) :
                (
                  <div className="loader-outer">
                    <div className="loader">
                      <span className="dot "></span>
                      <span className="dot "></span>
                      <span className="dot "></span>
                      <span className="dot "></span>
                      <span className="dot "></span>
                    </div>
                  </div>
                )
            }
            <Box
              className="Refres_button"
            // onClick={updateAuth}
            >
              <Button onClick={getData}>
                <RefresIcon />
              </Button>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Header;
