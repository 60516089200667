import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const ProfileContext = createContext();
ProfileProvider.propTypes = {
  children: PropTypes.node,
};
function ProfileProvider({ children }) {
  const liveLineLink = 'https://sport-jao7w.ondigitalocean.app';
  const [profileId, setProfileId] = useState();
  const [profileTabIndex, setProfileTabIndex] = useState(0);
  useEffect(() => {
    if (profileId) {
      localStorage.setItem('profileId', JSON.stringify(profileId));
    }
  }, [profileId])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setProfileId(JSON.parse(localStorage.getItem("profileId")));
    }
  }, [])

  return (
    <ProfileContext.Provider
      value={{
        profileId,
        profileTabIndex,
        liveLineLink,
        setProfileId,
        setProfileTabIndex,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileContext, ProfileProvider };
