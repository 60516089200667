import React from 'react'
import Header from './Header'
import PreHeader from './PreHeader'

const index = () => {
  return (<>
    <Header />
    <PreHeader />
  </>)
}

export default index