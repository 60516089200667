import {
  Box,
  Button,
  Container,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { DropIcon, LogoutIcon } from "../../assets/icons/Icon";
import { ProfileContext } from "../../contexts/ProfileContext";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import { useEffect } from "react";

const PreHeader = () => {
  const { setProfileTabIndex } = useContext(ProfileContext);
  const navigate = useNavigate();
  const { roleId } = useAuth();
  const { logout, loading } = useAuth();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const onClickProfile = () => {
    setProfileTabIndex(0);
    navigate("/my-account/profile");
  };
  // const logout = async () => {
  //     await axiosInstance.post('logout').then(()=>{
  //         localStorage.clear()
  //         window.location.reload()
  //     })

  // }
  // console.log(roleId, "role");
  return (
    <Box className="pre_hed">
      <Container maxW="sm,md,lg,xl">
        <List className="Pre_Header">
          <ListItem>
            <NavLink activeclassname="Active_Class" to="/dashboard">
              Dashboard
            </NavLink>
          </ListItem>
          <ListItem className="Menu-Drop">
            <Link>
              Downline List <DropIcon />
            </Link>
            <List className="show">
              {roleId === 2 && (
                <>
                  <ListItem>
                    <NavLink activeclassname="Active_Class" to="/user/list">
                      User Downline List{" "}
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink activeclassname="Active_Class" to="/master/list">
                      Master Downline List{" "}
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink
                      activeclassname="Active_Class"
                      to="/super_master/list"
                    >
                      Super Master Downline List{" "}
                    </NavLink>
                  </ListItem>
                </>
              )}
              {roleId === 3 && (
                <>
                  <ListItem>
                    <NavLink activeclassname="Active_Class" to="/user/list">
                      User Downline List{" "}
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink activeclassname="Active_Class" to="/master/list">
                      Master Downline List{" "}
                    </NavLink>
                  </ListItem>
                </>
              )}
              {roleId === 4 && (
                <ListItem>
                  <NavLink activeclassname="Active_Class" to="/user/list">
                    User Downline List{" "}
                  </NavLink>
                </ListItem>
              )}
            </List>
          </ListItem>
          <ListItem>
            <NavLink
              activeclassname="Active_Class"
              to="/my-account/profile"
              onClick={() => onClickProfile()}
            >
              My Account
            </NavLink>
          </ListItem>
          <ListItem className="Menu-Drop">
            <Link>
              My Report <DropIcon />
            </Link>
            <List className="show">
              <ListItem>
                <NavLink
                  activeclassname="Active_Class"
                  to="/reports/profit-loss"
                >
                  Event Profit/Loss{" "}
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  activeclassname="Active_Class"
                  to="/reports/downline-pl"
                >
                  {" "}
                  Downline Profit/Loss{" "}
                </NavLink>
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <NavLink activeclassname="Active_Class" to="/bet-list">
              BetList
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink activeclassname="Active_Class" to="/market-analysis">
              Market Analysis
            </NavLink>
          </ListItem>
          <ListItem className="Menu-Drop">
            <Link>
              Banking <DropIcon />
            </Link>
            {roleId === 2 && (
              <List className="show">
                <ListItem>
                  <NavLink
                    activeclassname="Active_Class"
                    to="/banking/user-banking"
                  >
                    User Banking{" "}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    activeclassname="Active_Class"
                    to="/banking/Masterbanking"
                  >
                    Master Banking{" "}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    activeclassname="Active_Class"
                    to="/banking/SupermasterBanking"
                  >
                    Supermaster Banking{" "}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    activeclassname="Active_Class"
                    to="/banking/BankingRequest"
                  >
                    Banking Request{" "}
                  </NavLink>
                </ListItem>
              </List>
            )}
            {roleId === 3 && (
              <List className="show">
                <ListItem>
                  <NavLink
                    activeclassname="Active_Class"
                    to="/banking/user-banking"
                  >
                    User Banking{" "}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    activeclassname="Active_Class"
                    to="/banking/Masterbanking"
                  >
                    Master Banking{" "}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    activeclassname="Active_Class"
                    to="/banking/BankingRequest"
                  >
                    Banking Request{" "}
                  </NavLink>
                </ListItem>
                {/* <ListItem><NavLink activeclassname='Active_Class' to="/banking/SupermasterBanking">Supermaster Banking  </NavLink></ListItem> */}
              </List>
            )}
            {roleId === 4 && (
              <List className="show">
                <ListItem>
                  <NavLink
                    activeclassname="Active_Class"
                    to="/banking/user-banking"
                  >
                    User Banking{" "}
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    activeclassname="Active_Class"
                    to="/banking/BankingRequest"
                  >
                    Banking Request{" "}
                  </NavLink>
                </ListItem>
                {/* <ListItem><NavLink activeclassname='Active_Class' to="/banking/Masterbanking">Master Banking  </NavLink></ListItem> */}
                {/* <ListItem><NavLink activeclassname='Active_Class' to="/banking/SupermasterBanking">Supermaster Banking  </NavLink></ListItem> */}
              </List>
            )}
          </ListItem>
          {roleId === 2 && (
            <ListItem className="Menu-Drop">
              <Link>
                Sports Setting <DropIcon />
              </Link>
              <List className="show">
                {roleId === 2 && (
                  <>
                    <ListItem>
                      <NavLink
                        activeclassname="Active_Class"
                        to="/setting/manage-fancy"
                      >
                        Manage Fancy{" "}
                      </NavLink>
                    </ListItem>
                    <ListItem>
                      <NavLink
                        activeclassname="Active_Class"
                        to="/setting/liveline-mapping"
                      >
                        Live Line Mapping{" "}
                      </NavLink>
                    </ListItem>
                    <ListItem>
                      <NavLink
                        activeclassname="Active_Class"
                        to="/setting/match-result"
                      >
                        Match Result{" "}
                      </NavLink>
                    </ListItem>
                    <ListItem>
                      <NavLink activeclassname="Active_Class" to="/setting">
                        Match Setting{" "}
                      </NavLink>
                    </ListItem>
                    <ListItem>
                      <NavLink
                        activeclassname="Active_Class"
                        to="/setting/match-min-max"
                      >
                        Match min max{" "}
                      </NavLink>
                    </ListItem>
                  </>
                )}
                <ListItem>
                  <NavLink activeclassname="Active_Class" to="/restore-user">
                    Restore User
                  </NavLink>
                </ListItem>
              </List>
            </ListItem>
          )}
          {roleId === 3 && (
            <ListItem>
              <NavLink activeclassname="Active_Class" to="/restore-user">
                Restore User
              </NavLink>
            </ListItem>
          )}
          {roleId === 4 && (
            <ListItem>
              <NavLink activeclassname="Active_Class" to="/restore-user">
                Restore User
              </NavLink>
            </ListItem>
          )}

          {/* <ListItem>
                        <NavLink activeClassName='Active_Class' exact to="/setting">Setting</NavLink>
                    </ListItem> */}
          {roleId === 2 && (
            <ListItem>
              <NavLink activeclassname="Active_Class" to="/commission">
                Commission
              </NavLink>
            </ListItem>
          )}
          {roleId === 3 && (
            <ListItem>
              <NavLink activeclassname="Active_Class" to="/commission">
                Commission
              </NavLink>
            </ListItem>
          )}
          {roleId === 4 && (
            <ListItem>
              <NavLink activeclassname="Active_Class" to="/commission">
                Commission
              </NavLink>
            </ListItem>
          )}

          <ListItem>
            <NavLink activeclassname="Active_Class" to="/password-history">
              Password History
            </NavLink>
          </ListItem>

          {roleId === 2 && (
            <ListItem className="Menu-Drop">
              <Link>
                Extra <DropIcon />
              </Link>
              <List className="show">
                {/* <ListItem>
                                                <NavLink activeclassname='Active_Class' to="/extra/banner-update">Banner Update</NavLink></ListItem> */}
                <ListItem>
                  <NavLink
                    activeclassname="Active_Class"
                    to="/extra/news-update"
                  >
                    News Update
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink
                    activeclassname="Active_Class"
                    to="/extra/client-notification"
                  >
                    Client Notification
                  </NavLink>
                </ListItem>
              </List>
            </ListItem>
          )}
          <Spacer />
          <Box className="LogoutIcon" onClick={onOpen}>
            <ListItem>
              <Link>
                Logout <LogoutIcon />
              </Link>
            </ListItem>
          </Box>
        </List>
      </Container>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width={{ base: "70%" }}>
          <ModalHeader>Logout Confirmation</ModalHeader>
          <ModalBody pb={0} pt={0}>
            Are you sure you want to logout?
          </ModalBody>
          <ModalFooter>
            <Button
              background={'#ef4f36'}
              onClick={logout}
              mr={2}
              height={8}
              isLoading={loading}
            >
              Logout
            </Button>
            <Button colorScheme="blue" onClick={onClose} height={8}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PreHeader;
