

//API
export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';
export const LOCATION_API = process.env.REACT_APP_LOCATION_API || '';
export const EXCHANGE_API = process.env.REACT_APP_EXCHANGE_API || '';
export const MAINBOARD_API = process.env.REACT_APP_MAINBOARD_HOST_API_KEY || '';
export const SOCKET_API = process.env.REACT_APP_SOCKET_HOST_API_KEY || '';
export const CASINO_SOCKET_HOST_API = process.env.REACT_APP_CASINO || '';
export const CASINO_HOST_API = process.env.REACT_APP_CASINOS || '';
export const CASINOS_AUTH = process.env.REACT_APP_CASINOS_AUTH || '';