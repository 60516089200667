import React from 'react'
import { Box, Flex, List, ListItem, } from '@chakra-ui/react'

const LoadingScreen = () => {
    return (
        <Flex justifyContent={'center'} alignItems={'center'} height={'100vh'} bg={'rgba(51, 51, 51, 0.8)'}>
            <Box className='loading_wrap'>
                <List className="loading">
                    <ListItem>
                        <Box className='loadingio-spinner-dual-ball-srpf31lqtit'>
                            <Box className='ldio-dayih5pen3j'>
                                <Box></Box>
                                <Box></Box>
                                <Box></Box>
                            </Box>
                        </Box>
                    </ListItem>
                    <ListItem>Loading...</ListItem>
                </List>
            </Box>
        </Flex>
    )
}

export default LoadingScreen