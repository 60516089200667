import { Box, Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
// components


// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.number), // Example [1, 2]
  children: PropTypes.node.isRequired,
};

export default function RoleBasedGuard({ roles, children }) {
  // 1--> super admin
  // 2--> admin
  // 3--> super master
  // 4--> master
  // console.log(roles,"dododo");
  // Logic here to get current user role
  const { roleId } = useAuth();
  // console.log(roleId,"sese");
  if (roles.length > 0 && !roles.includes(roleId)) {
    // return hasContent ? (
    return (
      <Box bg={'#fff'} textAlign="center">
        <Box backgroundImage="url('/access.webp')"
          backgroundPosition="center"
          backgroundRepeat="no-repeat" textAlign={'center'}
          width={'100%'}
          height={'100vh'}
          position='relative'
        >
        </Box>
        <Button mb={'15px'} bg="#204382" color={'#fff'} w={150}>Home</Button>
      </Box>
    )
  }

  return <>{children}</>;
}
