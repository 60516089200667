import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  // (error) =>
    // if (error?.response?.data?.errors[0]?.message === 'E_UNAUTHORIZED_ACCESS: Unauthorized access') window.location.href = '/auth/login';
    // error.response.status === 401 ? window.location.href = '/auth/login' : Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;


// if(selectedEvent?.sortOrder === item?.sortOrder) {
//   toast({
//       description: 'Match details cannot be updated due to same sort order.',
//       position: 'top-right',
//       status: 'success',
//       duration: 5000,
//       isClosable: true,
//   })
// }