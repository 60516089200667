import Router from './routes';
import './assets/styles/Header.css'
import './assets/styles/App.css';
import './assets/styles/Global.css';
import './assets/styles/Responsive.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { memo, useEffect } from 'react';

function App() {

  // useEffect(() => {
  //   const handleRightClick = (event) => {
  //     // Prevent the default right-click behavior
  //     event.preventDefault();
  //   };

  //   // Add the event listener to the document
  //   document.addEventListener('contextmenu', handleRightClick);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     document.removeEventListener('contextmenu', handleRightClick);
  //   };
  // }, []);


  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     console.log('keylog', event);
  //     // Disable shortcut keys that can open developer tools
  //     if (
  //       (event.ctrlKey && event.shiftKey && event.key === 'I') ||
  //       (event.metaKey && event.altKey && event.key === 'I') || (event.ctrlKey && event.shiftKey && event.key === 'J') || // for Windows/Linux
  //       (event.metaKey && event.altKey && event.key === 'J') || (event.key === 'F12')
  //     ) {
  //       event.preventDefault();
  //     }
  //   };

  //   // Add the event listener to the document
  //   document.addEventListener('keydown', handleKeyDown);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);
  
  return (
    <Router />
  );
}

export default memo(App);
