import { useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';


// utils
import axios from '../utils/axios';
import CommonToast from '../components/common/Toast/CommonToast';

// ----------------------------------------------------------------------
const setSession = (user, isLogin = false, printToast = null) => {
  if (user) {
    localStorage.setItem('ADMIN', JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `Bearer ${user.token}`;
    if (isLogin) {

      // const data = {
      //   description:"Login Successfully",
      //   status: 'success',
      //   containerStyle: {
      //     fontWeight: 'bolder'
      //   },
      // };
      // printToast(data);

      // toast({
      //   description: "Login Successfully",
      //   status: 'success',
      //   duration: 700,
      //   isClosable: true,
      //   position: "top-right",
      //   containerStyle: {
      //     fontWeight: 'bolder'
      //   },
      // })
    }
  } else {
    localStorage.clear();
    sessionStorage.clear();
    delete axios.defaults.headers.common.Authorization
  }
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  // method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateAuth: () => Promise.resolve(),
});
// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userId, setUserId] = useState();
  const [roleId, setRoleId] = useState();
  const { printToast } = CommonToast();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // console.log('API request failed:', error);
      if (error?.response?.status === 401) {
        if (error?.response?.statusText === 'Unauthorized' || error?.response?.data?.errors[0]?.message === 'E_UNAUTHORIZED_ACCESS: Unauthorized access') {
          localStorage.clear()
          sessionStorage.clear();
          delete axios.defaults.headers.common.Authorization;
          const data = {
            title: "Session Expired",
            description: "Please login again !!!",
            status: 'error',
            containerStyle: {
              fontWeight: 'bolder'
            },
          };
          printToast(data);

          // toast({
          //   title: "Session Expired",
          //   description: "Please login again !!!",
          //   status: 'error',
          //   duration: 700,
          //   isClosable: true,
          //   position: "top-right",
          //   containerStyle: {
          //     fontWeight: 'bolder'
          //   },
          // })

          window.location.replace('/')
        }
      }
      throw error;
    }
  );

  useEffect(() => {
    const initialize = async () => {

      const user = JSON.parse(localStorage.getItem('ADMIN'));

      if (user) {
        setUserId(user.user?.id)
        setRoleId(user.user?.role_id)
        axios.defaults.headers.common.Authorization = `Bearer ${user.token}`;
        try {
          const response = await axios.get('get_me');
          if (response) {
            setSession({ ...user, ...{ user: response.data } });
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: response.data,
              },
            });
          } else {
            localStorage.clear();
            sessionStorage.clear();
          }
        } catch (err) {
          setSession(null);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }

      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const updateAuth = async () => {
    const user = JSON.parse(localStorage.getItem('ADMIN'));
    try {
      const response = await axios.get('get_me');
      setSession({ ...user, ...{ user: response.data } });
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user: response.data,
        },
      });
    } catch (err) {
      setSession(null);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }

  const login = async (username, password, locationData) => {
    const response = await axios.post('login', {
      username,
      password,
      locationData
    });
    const isLogin = true
    setSession(response.data, isLogin, printToast);
    // console.log(response.data?.user?.change_password === 0, 'chhhhhh');
    window.location.reload()
    const { user } = response.data
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };


  const logout = async () => {
    // setLoading(true);
    await axios.get('logout');
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    // setLoading(false);
    window.location.replace('/')
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        // method: 'jwt',
        login,
        roleId,
        logout,
        userId,
        updateAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
