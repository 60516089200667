import {
    Box,
    Button,
    Flex,
    FormControl,
    FormHelperText,
    Image,
    Input,
    Text,
    VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Headerlogosvg, LoginIcon } from "../../assets/icons/Icon";
import { LOCATION_API } from "../../config";
import useAuth from "../../hooks/useAuth";
import axiosInstance from "../../utils/axios";
import CommonToast from "../../components/common/Toast/CommonToast";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
const ChangePasswordFirstLogin = () => {
    const { login,logout } = useAuth();
    const { printToast } = CommonToast();
    const [locationData, setLocationData] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [errormsg, setErrormsg] = useState();
    const { user } = useAuth();
    const navigate = useNavigate();
    const submitHandler = async (e) => {
        e.preventDefault();
        setIsSubmit(true);
        setLoading(true);
        setIsSubmitting(true);
        if (username && password) {
            try {
                await login(username, password, locationData);
                // toast({
                //     description: 'login successfully',
                //     position: 'top-right',
                //     status: 'success',
                //     duration: 4000,
                //     isClosable: true,
                //   });
                const data = {
                    description: "Login Successfully",
                    status: 'success',
                };
                printToast(data);

                // toast({
                //   title: "Login Successfully",
                //   position: "top-right",
                //   isClosable: true,
                //   status: "success",
                //   duration: 5000,
                // });
            } catch (error) {
                setError(true);
                setErrormsg(error.response.data);
            }
        }
        setLoading(false);
        setIsSubmitting(false);
    };

    const formik = useFormik({
        initialValues: {
            master_password: '',
            password: '',
            confirm_password: '',
            user_id: user?.id
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            master_password: Yup.string().required('Password is required'),
            password: Yup.string().required('Password is required').min(6),
            confirm_password: Yup.string().required('Confirm Password is required')
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        }),
        onSubmit: async (values) => {

            try {
                const response = await axiosInstance.post(`change_password`, values);
                if (response?.status === 200) {
                    console.log('response_', response);
                    const data = {
                        title: 'success',
                        status: 'success',
                        duration: 4000,
                    };
                    printToast(data);
                    logout()
                    window.location.replace('/')
                    //   setShowPasswordPopup()
                    // setRefresh(pre => pre + 1)
                }
            } catch (error) {
                console.log(error.response.data.master_password, "error")
                // const description = error.response.data.master_password || 'Something Went Wrong';
                console.log('error_', error);
                const description = error?.response?.data?.master_password || 'Something Went Wrong';
                const data = {
                    title: 'Error',
                    description,
                    status: 'error',
                    duration: 4000,
                };
                printToast(data);
                // toast({
                //     title: 'Error',
                //     description: error.response.data.master_password || 'Something Went Wrong',
                //     position: 'top-right',
                //     isClosable: true,
                //     status: 'error',
                //     duration: 9000,
                // })
                formik.setFieldError('master_password', error?.master_password)
                error?.errors?.length && error?.errors?.forEach(element => {
                    formik.setFieldError(element?.field, element?.message)
                });
            }
        },
    });

    //   useEffect(() => {
    //     if (!username && !password) {
    //       setError();
    //     }
    //   }, [username, password]);

    return (
        <>
            <Flex
                align={"center"}
                justify={"center"}
                height={{ base: '100vh', sm: '100vh', md: '100vh' }}
                style={{ background: "url(../bg-login.jpg)" }}
            >
                <Box
                    className="login-wrap"
                    style={{
                        backdropFilter: "blur(2px)",
                        boxShadow: "0px 10px 15px 10px rgb(0 0 0 / 15%)",
                        WebkitBackdropFilter: "blur(2px)",
                        backgroundColor: "rgb(228 228 228 / 15%)",
                        zIndex: 1,
                    }}
                    // bg="linear-gradient(180deg, #580575 8%, #4a0562 100%)"
                    // bg="linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)"
                    p={6}
                    rounded="md"
                    w={{ base: '100%', sm: '100%', md: '540px' }}
                    py={{ base: '20px', sm: '20px', md: "100px" }}
                    px={{ base: '50px', sm: '50px', md: "150px" }}
                    // height={{base:'100%',sm:'100%',md:'auto'}}
                    mx={{ base: '2', sm: '2', md: '0' }}
                >
                    <Image
                        className="logo_wrap"
                        w={"250px"}
                        margin={"auto"}
                        pb={"10px"}
                        // src="/jdlogopng.png"
                        src="/jdexlogo.svg"
                        alt=""
                    />
                    <Text
                        fontSize="20px"
                        lineHeight="24px"
                        mb="15px"
                        fontWeight="500"
                        color="#fff"
                        className="log"
                    >
                        Change Password
                    </Text>
                    <form onSubmit={formik.handleSubmit} >
                        <VStack spacing={4} align="flex-start">
                            {isSubmit && error ? (
                                <Text fontWeight={400} mt={0} color={"red"}>
                                    {error}
                                </Text>
                            ) : null}
                            <FormControl>
                                <Input
                                    className="Form_Control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Your Password.."
                                    type="password"
                                    name='master_password'
                                />
                                {formik.touched.master_password && formik.errors.master_password &&
                                    <FormHelperText fontSize={12} fontWeight={400} mt={0}
                                        color={'red'}>{formik.errors.master_password}</FormHelperText>}
                            </FormControl>
                            <FormControl>
                                <Input
                                    className="Form_Control"
                                    name='password'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="New Password"
                                    type="password"
                                />
                                {formik.touched.password && formik.errors.password &&
                                    <FormHelperText fontSize={12} fontWeight={400} mt={0}
                                        color={'red'}>{formik.errors.password}</FormHelperText>}
                            </FormControl>
                            <FormControl>
                                <Input
                                    className="Form_Control"
                                    name='confirm_password' 
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    placeholder="Confirm Password"
                                    type="password"
                                />
                                {formik.touched.confirm_password && formik.errors.confirm_password &&
                                    <FormHelperText fontSize={12} fontWeight={400} mt={0}
                                        color={'red'}>{formik.errors.confirm_password}</FormHelperText>}
                            </FormControl>

                            <Box w="100%" className="LogoutIcon">

                                <Button
                                    background='#000'
                                    color='#fff'
                                    fontSize={15}
                                    type="submit"
                                    isLoading={loading}
                                    loadingText="Loading..."
                                    disabled={isSubmitting}
                                    _hover={"#000"}
                                    w="100%"
                                    position="relative"
                                >
                                    <Box
                                        margin='auto'
                                        position="absolute"
                                        left="50%"
                                        top="50%"
                                        transform="translate(-50%, -50%)"
                                    >
                                        Change Password <LoginIcon />
                                    </Box>
                                </Button>
                            </Box>
                        </VStack>
                    </form>
                </Box>
            </Flex >
        </>
    )
}

export default ChangePasswordFirstLogin